import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FaqLayout from "../../components/faq-layout"
import locale from "../../locale/en"
import FaceShieldsContent from "../../components/faq-content/face-shields"

const { faq } = locale

const FaceShields = () => (
  <Layout>
    <SEO title={faq.face_shields} />
    <FaqLayout title={faq.face_shields}>
      <FaceShieldsContent />
    </FaqLayout>
  </Layout>
)

export default FaceShields
